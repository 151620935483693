<template>
  <div>
    <!-- PAYMENT SUCCESS PAGE -->
    <section class="bg-home d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-12 text-center">
            <img src="images/app/success.png" class="img-fluid" alt="">
            <div class="text-uppercase mt-4 display-3">Payment Successful!</div>
            <div class="text-capitalize text-dark mb-4 error-page">Thanks for joining.</div>
            <p class="text-muted para-desc mx-auto">Your order has been processed.<span v-show="countdown > 0" class="text-muted ml-2">Go back after {{ countdown }}s</span> </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-primary mt-4" @click="goBack()">Go Back</button>

          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!--    <Switcher/>-->
  </div>
</template>

<script>
import Switcher from "@/components/switcher";
import {
  HomeIcon
} from 'vue-feather-icons';

/**
 * Payment-success component
 */
export default {
  data() {
    return {
      countdown: 5,
      intervalId: null,
      code: '',
      msg: ''


    }
  },
  components: {
    Switcher,
    HomeIcon
  },
  created() {
    this.code = this.$route.query.code
    this.msg = this.$route.query.msg
    console.log("code:" + this.code)
    console.log("msg:" + this.msg)
    if(this.code !== undefined){

    }
    // start the countdown timer
    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        // if the countdown is finished, go back to the previous page
        clearInterval(this.intervalId);
        this.goBack();
      }
    }, 1000);
  },
  methods: {
    goBack() {
      // use vue-router to go back to the previous page
      // this.$router.go(-1);
      // window.location.href="/profile/overview"
      this.$router.push("/profile/deposits")
    }
  }
}
</script>
